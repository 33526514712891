<template>
  <div class="navbar navbar-expand-lg fixed-top navbar-dark">
    <div class="container">
      <a href="https://d0j1a1701.cc" class="navbar-brand d-flex align-items-center">
        <img src="https://cdn.jsdelivr.net/gh/d0j1a1701/image-host@master/3e7ebd3bc21dd85884fcd4fd604392791643352755.png" width="24" height="24" class="me-2" />
        d0j1a_1701
      </a>
    </div>
  </div>
  <div id="banner" class="py-4">
    <div class="container py-4">
      <h1 class="text-light display-6 fw-bold">d0j1a_1701的实验室</h1>
      <p class="col-md-8 fs-6">
        一些有趣的东西。
      </p>
    </div>
  </div>
  <div class="col container py-5">
    <div class="row g-3 justify-content-center">
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card h-100">
          <div class="card-header text-light">
            <span class="fs-6">Virtual Judge</span>
          </div>
          <div class="card-body d-flex flex-column">
            <p class="flex-fill card-text">
              使用Vercel反向代理的VJudge镜像。
              <br />
              支持登陆账号并提交题目，全运营商可用。
            </p>
            <div class="row gx-1 align-items-center mb-3">
              <div class="col-auto">
                <span class="badge bg-secondary">Vercel</span>
              </div>
              <div class="col-auto">
                <span class="badge bg-success">Stable</span>
              </div>
            </div>
            <div class="row gx-1 align-items-center justify-content-end">
              <div class="col-auto">
                <a
                  href="https://vjudge.d0j1a1701.cc"
                  class="btn"
                  target="_blank"
                  >Link</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card h-100">
          <div class="card-header text-light">
            <span class="fs-6">Luogu Card</span>
          </div>
          <div class="card-body d-flex flex-column">
            <p class="flex-fill card-text">
              Luogu 个人信息卡。
            </p>
            <div class="row gx-1 align-items-center mb-3">
              <div class="col-auto">
                <div class="row gx-1 align-items-center">
                  <div class="col-auto">
                    <span class="badge bg-secondary">Vercel</span>
                  </div>
                  <div class="col-auto">
                    <span class="badge bg-warning">Unstable</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-1 align-items-center justify-content-end">
              <div class="col-auto">
                <a
                  href="https://easydoc.net/doc/46361386/mxP9gQ7z/4ehOH3I8"
                  class="btn"
                  target="_blank"
                  >Link</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card h-100">
          <div class="card-header text-light">
            <span class="fs-6">Random Picture</span>
          </div>
          <div class="card-body d-flex flex-column">
            <p class="flex-fill card-text">
              随机插画API。
            </p>
            <div class="row gx-1 align-items-center mb-3">
              <div class="col-auto">
                <div class="row gx-1 align-items-center">
                  <div class="col-auto">
                    <span class="badge bg-secondary">Cloudflare Worker</span>
                  </div>
                  <div class="col-auto">
                    <span class="badge bg-success">Stable</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-1 align-items-center justify-content-end">
              <div class="col-auto">
                <a href="https://www.d0j1a1701.cc/random-pic" class="btn" target="_blank">Link</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <div class="card h-100">
          <div class="card-header text-light">
            <span class="fs-6">Phisimulator</span>
          </div>
          <div class="card-body d-flex flex-column">
            <p class="flex-fill card-text">
              Phigros 模拟器。
            </p>
            <div class="row gx-1 align-items-center mb-3">
              <div class="col-auto">
                <div class="row gx-1 align-items-center">
                  <div class="col-auto">
                    <span class="badge bg-secondary">Vercel</span>
                  </div>
                  <div class="col-auto">
                    <span class="badge bg-success">Stable</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row gx-1 align-items-center justify-content-end">
              <div class="col-auto">
                <a href="https://phi.d0j1a1701.cc" class="btn" target="_blank">Link</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="bd-footer py-5 bg-dark">
    <div class="container">
    <div class="small w-75 mb-2">
      Made by 
      <a style="color:rgb(173,186,199)" href="https://freddecgames.com/">Freddec Games.</a>
      <br />
      Modify by d0j1a_1701,with ❤.
    </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
